<template>
  <section class="section is-title-bar">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <ul>
            <li
              v-for="(title,index) in titleStack"
              :key="index"
            >
              {{ title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <h2 class="subtitle">{{subtitle}}</h2>
  </section>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    titleStack: {
      type: Array,
      default: () => []
    },
    subtitle: {
      type: String,
      default: () => {}
    }
  }
}
</script>

<style scoped>
.v-label-white {
  color: red;
  font-weight: bolder
}
</style>
