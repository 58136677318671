export default Object.freeze({
  getModules: {
    GET: () => {
      return 'modules/modules'
    }
  },
  addModule: {
    GET: 'modules/module'
  },
  editModule: {
    GET: (id) => {
      return `modules/${id}`
    }
  },
  getModule: {
    GET: (id) => {
      return `modules/${id}`
    }
  },
  deleteModule: {
    GET: (id) => {
      return `modules/${id}`
    }
  },
  getModuleGroups: {
    GET: (id) => {
      return `modules/${id}/groups`
    }
  }
})
