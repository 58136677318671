import groupEndpoints from './endpoints/group-endpoints'
import apiConfig from '../config/api'
import http from './http'
// import store from "../store/index";

const {
  getGroups,
  getGroup,
  addGroup,
  editGroup,
  deleteGroup,
  getGroupStudents,
  getGroupLevels,
  getGroupTypes,
  getGroupLessons,
  getGroupAttendance,
  getGroupLessonsWithoutAttendance,
  getTeacherGroups,
  getGroupAttendanceReport
} = groupEndpoints
const { baseURL } = apiConfig

const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjRWVEhnbTVTUTltY25QVEVVQ0p1dyJ9.eyJpc3MiOiJodHRwczovL2Rldi10NTVwcTdieS5ldS5hdXRoMC5jb20vIiwic3ViIjoibHd3V3JaQ05TdDNLeGY5RGIzNjgzZmtUQk9SZ2J5M1JAY2xpZW50cyIsImF1ZCI6InByb2xpbmd1YSIsImlhdCI6MTY1Mjg4MjIxOCwiZXhwIjoxNjUyOTY4NjE4LCJhenAiOiJsd3dXclpDTlN0M0t4ZjlEYjM2ODNma1RCT1JnYnkzUiIsImd0eSI6ImNsaWVudC1jcmVkZW50aWFscyJ9.V512m-sZCtXkeetZgUFbWfTxzkXjakU7HvOrSACGOcK7Xpc8GKxK-MagebkqSKrfabHJe9imENDcCtxr1CPAFwrkOVXCNLEob4Gjxxs_pOJSv5Rg4yPXdhyi2QDhrVYjT2J4aRe4Dh_9mRnwzKwPyugn04u5ClIl4dBAkn7m9TyJlGgWyaEzYtup0qn0-KKqVFIF0HPRE4ai6rEjUsiCBlK9Gfzvuwua8eJOL4s8EJEkYUjVXdsCLxRVAoGQRypgc0wO0Nc-qTpVdpeS_wnWS8MfAjrUPFYFjzgZZar8Y6RhpANdi5YiryNwGtW2_Gqp7IKRxfW8R0uZeNdV1k39_g'

const GroupService = {
  async getGroupAttendanceReport (groupId) {
    const getGroupAttendanceReportUrl = `${baseURL}${getGroupAttendanceReport.GET(groupId)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getGroupAttendanceReportUrl,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },
  async getGroups () {
    const getGroupsURL = `${baseURL}${getGroups.GET()}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getGroupsURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async addGroup (group) {
    var today = new Date()
    const schedule = {}
    if (group.schedule.mondayStart) {
      schedule.mondayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.mondayStart.HH), parseInt(group.schedule.mondayStart.mm), 0, 0)).toISOString()
    }
    if (group.schedule.mondayEnd) {
      schedule.mondayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.mondayEnd.HH), parseInt(group.schedule.mondayEnd.mm), 0, 0)).toISOString()
    }
    if (group.schedule.tuesdayStart) {
      schedule.tuesdayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.tuesdayStart.HH), parseInt(group.schedule.tuesdayStart.mm), 0, 0)).toISOString()
    }
    if (group.schedule.tuesdayEnd) {
      schedule.tuesdayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.tuesdayEnd.HH), parseInt(group.schedule.tuesdayEnd.mm), 0, 0)).toISOString()
    }
    if (group.schedule.wednesdayStart) {
      schedule.wednesdayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.wednesdayStart.HH), parseInt(group.schedule.wednesdayStart.mm), 0, 0)).toISOString()
    }
    if (group.schedule.wednesdayEnd) {
      schedule.wednesdayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.wednesdayEnd.HH), parseInt(group.schedule.wednesdayEnd.mm), 0, 0)).toISOString()
    }
    if (group.schedule.thursdayStart) {
      schedule.thursdayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.thursdayStart.HH), parseInt(group.schedule.thursdayStart.mm), 0, 0)).toISOString()
    }
    if (group.schedule.thursdayEnd) {
      schedule.thursdayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.thursdayEnd.HH), parseInt(group.schedule.thursdayEnd.mm), 0, 0)).toISOString()
    }
    if (group.schedule.fridayStart) {
      schedule.fridayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.fridayStart.HH), parseInt(group.schedule.fridayStart.mm), 0, 0)).toISOString()
    }
    if (group.schedule.fridayEnd) {
      schedule.fridayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.fridayEnd.HH), parseInt(group.schedule.fridayEnd.mm), 0, 0)).toISOString()
    }
    if (group.schedule.saturdayStart) {
      schedule.saturdayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.saturdayStart.HH), parseInt(group.schedule.saturdayStart.mm), 0, 0)).toISOString()
    }
    if (group.schedule.saturdayEnd) {
      schedule.saturdayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.saturdayEnd.HH), parseInt(group.schedule.saturdayEnd.mm), 0, 0)).toISOString()
    }
    if (group.schedule.sundayStart) {
      schedule.sundayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.sundayStart.HH), parseInt(group.schedule.sundayStart.mm), 0, 0)).toISOString()
    }
    if (group.schedule.sundayEnd) {
      schedule.sundayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.sundayEnd.HH), parseInt(group.schedule.sundayEnd.mm), 0, 0)).toISOString()
    }

    group.schedule = schedule

    const getAddGroupUrl = `${baseURL}${addGroup.GET}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .post(
        getAddGroupUrl,
        {
          name: group.name,
          lessonDuration: group.lessonDuration,
          moduleId: group.moduleId,
          teacherId: group.teacherId,
          levelId: group.levelId,
          typeId: group.typeId,
          teacher: group.teacher,
          textBook: group.textBook,
          costPerTeacher: group.costPerTeacher,
          costPerClient: group.costPerClient,
          schedule: group.schedule
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async editGroup (group) {
    const getEditGroupUrl = `${baseURL}${editGroup.GET(group.id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    var today = new Date()
    const schedule = {}

    if (group.schedule.mondayStart) {
      if (group.schedule.mondayStart && group.schedule.mondayStart.HH && group.schedule.mondayStart.mm) {
        schedule.mondayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.mondayStart.HH), parseInt(group.schedule.mondayStart.mm), 0, 0)).toISOString()
      } else {
        const timeArray = group.schedule.mondayStart.split(':')
        const hour = parseInt(timeArray[0])
        const minute = parseInt(timeArray[1])
        schedule.mondayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(hour), parseInt(minute), 0, 0)).toISOString()
      }
    }
    if (group.schedule.mondayEnd) {
      if (group.schedule.mondayEnd && group.schedule.mondayEnd.HH && group.schedule.mondayEnd.mm) {
        schedule.mondayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.mondayEnd.HH), parseInt(group.schedule.mondayEnd.mm), 0, 0)).toISOString()
      } else {
        const timeArray = group.schedule.mondayEnd.split(':')
        const hour = parseInt(timeArray[0])
        const minute = parseInt(timeArray[1])
        schedule.mondayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(hour), parseInt(minute), 0, 0)).toISOString()
      }
    }
    if (group.schedule.tuesdayStart) {
      if (group.schedule.tuesdayStart && group.schedule.tuesdayStart.HH && group.schedule.tuesdayStart.mm) {
        schedule.tuesdayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.tuesdayStart.HH), parseInt(group.schedule.tuesdayStart.mm), 0, 0)).toISOString()
      } else {
        const timeArray = group.schedule.tuesdayStart.split(':')
        const hour = parseInt(timeArray[0])
        const minute = parseInt(timeArray[1])
        schedule.tuesdayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(hour), parseInt(minute), 0, 0)).toISOString()
      }
    }
    if (group.schedule.tuesdayEnd) {
      if (group.schedule.tuesdayEnd && group.schedule.tuesdayEnd.HH && group.schedule.tuesdayEnd.mm) {
        schedule.tuesdayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.tuesdayEnd.HH), parseInt(group.schedule.tuesdayEnd.mm), 0, 0)).toISOString()
      } else {
        const timeArray = group.schedule.tuesdayEnd.split(':')
        const hour = parseInt(timeArray[0])
        const minute = parseInt(timeArray[1])
        schedule.tuesdayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(hour), parseInt(minute), 0, 0)).toISOString()
      }
    }
    if (group.schedule.wednesdayStart) {
      if (group.schedule.wednesdayStart && group.schedule.wednesdayStart.HH && group.schedule.wednesdayStart.mm) {
        schedule.wednesdayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.wednesdayStart.HH), parseInt(group.schedule.wednesdayStart.mm), 0, 0)).toISOString()
      } else {
        const timeArray = group.schedule.wednesdayStart.split(':')
        const hour = parseInt(timeArray[0])
        const minute = parseInt(timeArray[1])
        schedule.wednesdayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(hour), parseInt(minute), 0, 0)).toISOString()
      }
    }
    if (group.schedule.wednesdayEnd) {
      if (group.schedule.wednesdayEnd && group.schedule.wednesdayEnd.HH && group.schedule.wednesdayEnd.mm) {
        schedule.wednesdayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.wednesdayEnd.HH), parseInt(group.schedule.wednesdayEnd.mm), 0, 0)).toISOString()
      } else {
        const timeArray = group.schedule.wednesdayEnd.split(':')
        const hour = parseInt(timeArray[0])
        const minute = parseInt(timeArray[1])
        schedule.wednesdayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(hour), parseInt(minute), 0, 0)).toISOString()
      }
    }
    if (group.schedule.thursdayStart) {
      if (group.schedule.thursdayStart && group.schedule.thursdayStart.HH && group.schedule.thursdayStart.mm) {
        schedule.wednesdayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.thursdayStart.HH), parseInt(group.schedule.thursdayStart.mm), 0, 0)).toISOString()
      } else {
        const timeArray = group.schedule.thursdayStart.split(':')
        const hour = parseInt(timeArray[0])
        const minute = parseInt(timeArray[1])
        schedule.thursdayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(hour), parseInt(minute), 0, 0)).toISOString()
      }
    }
    if (group.schedule.thursdayEnd) {
      if (group.schedule.thursdayEnd && group.schedule.thursdayEnd.HH && group.schedule.thursdayEnd.mm) {
        schedule.wednesdayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.thursdayEnd.HH), parseInt(group.schedule.thursdayEnd.mm), 0, 0)).toISOString()
      } else {
        const timeArray = group.schedule.thursdayEnd.split(':')
        const hour = parseInt(timeArray[0])
        const minute = parseInt(timeArray[1])
        schedule.thursdayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(hour), parseInt(minute), 0, 0)).toISOString()
      }
    }
    if (group.schedule.fridayStart) {
      if (group.schedule.fridayStart && group.schedule.fridayStart.HH && group.schedule.fridayStart.mm) {
        schedule.fridayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.fridayStart.HH), parseInt(group.schedule.fridayStart.mm), 0, 0)).toISOString()
      } else {
        const timeArray = group.schedule.fridayStart.split(':')
        const hour = parseInt(timeArray[0])
        const minute = parseInt(timeArray[1])
        schedule.fridayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(hour), parseInt(minute), 0, 0)).toISOString()
      }
    }
    if (group.schedule.fridayEnd) {
      if (group.schedule.fridayEnd && group.schedule.fridayEnd.HH && group.schedule.fridayEnd.mm) {
        schedule.fridayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.fridayEnd.HH), parseInt(group.schedule.fridayEnd.mm), 0, 0)).toISOString()
      } else {
        const timeArray = group.schedule.fridayEnd.split(':')
        const hour = parseInt(timeArray[0])
        const minute = parseInt(timeArray[1])
        schedule.fridayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(hour), parseInt(minute), 0, 0)).toISOString()
      }
    }
    if (group.schedule.saturdayStart) {
      if (group.schedule.saturdayStart && group.schedule.saturdayStart.HH && group.schedule.saturdayStart.mm) {
        schedule.fridayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.saturdayStart.HH), parseInt(group.schedule.saturdayStart.mm), 0, 0)).toISOString()
      } else {
        const timeArray = group.schedule.saturdayStart.split(':')
        const hour = parseInt(timeArray[0])
        const minute = parseInt(timeArray[1])
        schedule.saturdayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(hour), parseInt(minute), 0, 0)).toISOString()
      }
    }
    if (group.schedule.saturdayEnd) {
      if (group.schedule.saturdayEnd && group.schedule.saturdayEnd.HH && group.schedule.saturdayEnd.mm) {
        schedule.saturdayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.saturdayEnd.HH), parseInt(group.schedule.saturdayEnd.mm), 0, 0)).toISOString()
      } else {
        const timeArray = group.schedule.saturdayEnd.split(':')
        const hour = parseInt(timeArray[0])
        const minute = parseInt(timeArray[1])
        schedule.saturdayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(hour), parseInt(minute), 0, 0)).toISOString()
      }
    }
    if (group.schedule.sundayStart) {
      if (group.schedule.sundayStart && group.schedule.sundayStart.HH && group.schedule.sundayStart.mm) {
        schedule.sundayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.sundayStart.HH), parseInt(group.schedule.sundayStart.mm), 0, 0)).toISOString()
      } else {
        const timeArray = group.schedule.sundayStart.split(':')
        const hour = parseInt(timeArray[0])
        const minute = parseInt(timeArray[1])
        schedule.sundayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(hour), parseInt(minute), 0, 0)).toISOString()
      }
    }
    if (group.schedule.sundayEnd) {
      if (group.schedule.sundayEnd && group.schedule.sundayEnd.HH && group.schedule.sundayEnd.mm) {
        schedule.sundayStart = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(group.schedule.sundayEnd.HH), parseInt(group.schedule.sundayEnd.mm), 0, 0)).toISOString()
      } else {
        const timeArray = group.schedule.sundayEnd.split(':')
        const hour = parseInt(timeArray[0])
        const minute = parseInt(timeArray[1])
        schedule.sundayEnd = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), parseInt(hour), parseInt(minute), 0, 0)).toISOString()
      }
    }

    group.schedule = schedule

    return await http
      .put(
        getEditGroupUrl,
        {
          id: group.id,
          name: group.name,
          moduleId: group.moduleId,
          lessonDuration: group.lessonDuration,
          teacherId: group.teacherId,
          scheduleId: group.scheduleId,
          schedule: group.schedule,
          levelId: group.levelId,
          typeId: group.typeId,
          teacher: group.teacher,
          textBook: group.textBook,
          costPerTeacher: group.costPerTeacher,
          costPerClient: group.costPerClient
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getGroup (id) {
    const getGroupURL = `${baseURL}${getGroup.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getGroupURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async deleteGroup (id) {
    const getDeleteGroupUrl = `${baseURL}${deleteGroup.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .delete(
        getDeleteGroupUrl,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getGroupStudents (id) {
    const getGroupStudentsURL = `${baseURL}${getGroupStudents.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getGroupStudentsURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },
  async getGroupLevels () {
    const getGroupLevelsURL = `${baseURL}${getGroupLevels.GET()}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getGroupLevelsURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },
  async getGroupTypes () {
    const getGroupLevelsURL = `${baseURL}${getGroupTypes.GET()}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getGroupLevelsURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },
  async getGroupAttendance (id) {
    const getGroupAttendanceURL = `${baseURL}${getGroupAttendance.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getGroupAttendanceURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },
  async getGroupLessonsWithoutAttendance (id) {
    const getGroupLessonsWithoutAttendanceURL = `${baseURL}${getGroupLessonsWithoutAttendance.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getGroupLessonsWithoutAttendanceURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },
  async getGroupLessons (id) {
    const getGroupLessonsURL = `${baseURL}${getGroupLessons.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getGroupLessonsURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },
  async getTeacherGroups (id) {
    const getTeacherGroupsURL = `${baseURL}${getTeacherGroups.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getTeacherGroupsURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  }
}

export default GroupService
