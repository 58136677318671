import studentEndpoints from './endpoints/student-endpoints'
import apiConfig from '../config/api'
import http from './http'
// import store from "../store/index";

const {
  getStudents,
  getStudent,
  addStudent,
  editStudent,
  deleteStudent,
  getStudentAttendances,
  getStudentOverview
} = studentEndpoints
const { baseURL } = apiConfig

const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjRWVEhnbTVTUTltY25QVEVVQ0p1dyJ9.eyJpc3MiOiJodHRwczovL2Rldi10NTVwcTdieS5ldS5hdXRoMC5jb20vIiwic3ViIjoibHd3V3JaQ05TdDNLeGY5RGIzNjgzZmtUQk9SZ2J5M1JAY2xpZW50cyIsImF1ZCI6InByb2xpbmd1YSIsImlhdCI6MTY1Mjg4MjIxOCwiZXhwIjoxNjUyOTY4NjE4LCJhenAiOiJsd3dXclpDTlN0M0t4ZjlEYjM2ODNma1RCT1JnYnkzUiIsImd0eSI6ImNsaWVudC1jcmVkZW50aWFscyJ9.V512m-sZCtXkeetZgUFbWfTxzkXjakU7HvOrSACGOcK7Xpc8GKxK-MagebkqSKrfabHJe9imENDcCtxr1CPAFwrkOVXCNLEob4Gjxxs_pOJSv5Rg4yPXdhyi2QDhrVYjT2J4aRe4Dh_9mRnwzKwPyugn04u5ClIl4dBAkn7m9TyJlGgWyaEzYtup0qn0-KKqVFIF0HPRE4ai6rEjUsiCBlK9Gfzvuwua8eJOL4s8EJEkYUjVXdsCLxRVAoGQRypgc0wO0Nc-qTpVdpeS_wnWS8MfAjrUPFYFjzgZZar8Y6RhpANdi5YiryNwGtW2_Gqp7IKRxfW8R0uZeNdV1k39_g'

const StudentService = {
  async getStudents () {
    const getStudentsURL = `${baseURL}${getStudents.GET()}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getStudentsURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async addStudent (student) {
    const getAddStudentUrl = `${baseURL}${addStudent.GET}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .post(
        getAddStudentUrl,
        {
          firstName: student.firstName,
          lastName: student.lastName,
          groupId: student.groupId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async editStudent (student) {
    const getEditStudentUrl = `${baseURL}${editStudent.GET(student.id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .put(
        getEditStudentUrl,
        {
          id: student.id,
          firstName: student.firstName,
          lastName: student.lastName,
          groupId: student.groupId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getStudent (id) {
    const getStudentURL = `${baseURL}${getStudent.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getStudentURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async deleteStudent (id) {
    const getDeleteStudentUrl = `${baseURL}${deleteStudent.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .delete(
        getDeleteStudentUrl,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getStudentAttendances (studentId) {
    const getStudentURL = `${baseURL}${getStudentAttendances.GET(studentId)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getStudentURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },
  async getStudentOverview (studentId) {
    const getStudentOverviewURL = `${baseURL}${getStudentOverview.GET(studentId)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getStudentOverviewURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  }
}

export default StudentService
