import moduleEndpoints from './endpoints/module-endpoints'
import apiConfig from '../config/api'
import http from './http'
// import store from "../store/index";

const {
  getModules,
  getModule,
  addModule,
  editModule,
  deleteModule,
  getModuleGroups
} = moduleEndpoints
const { baseURL } = apiConfig

const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjRWVEhnbTVTUTltY25QVEVVQ0p1dyJ9.eyJpc3MiOiJodHRwczovL2Rldi10NTVwcTdieS5ldS5hdXRoMC5jb20vIiwic3ViIjoibHd3V3JaQ05TdDNLeGY5RGIzNjgzZmtUQk9SZ2J5M1JAY2xpZW50cyIsImF1ZCI6InByb2xpbmd1YSIsImlhdCI6MTY1Mjg4MjIxOCwiZXhwIjoxNjUyOTY4NjE4LCJhenAiOiJsd3dXclpDTlN0M0t4ZjlEYjM2ODNma1RCT1JnYnkzUiIsImd0eSI6ImNsaWVudC1jcmVkZW50aWFscyJ9.V512m-sZCtXkeetZgUFbWfTxzkXjakU7HvOrSACGOcK7Xpc8GKxK-MagebkqSKrfabHJe9imENDcCtxr1CPAFwrkOVXCNLEob4Gjxxs_pOJSv5Rg4yPXdhyi2QDhrVYjT2J4aRe4Dh_9mRnwzKwPyugn04u5ClIl4dBAkn7m9TyJlGgWyaEzYtup0qn0-KKqVFIF0HPRE4ai6rEjUsiCBlK9Gfzvuwua8eJOL4s8EJEkYUjVXdsCLxRVAoGQRypgc0wO0Nc-qTpVdpeS_wnWS8MfAjrUPFYFjzgZZar8Y6RhpANdi5YiryNwGtW2_Gqp7IKRxfW8R0uZeNdV1k39_g'

const ModuleService = {
  async getModules () {
    const getModulesURL = `${baseURL}${getModules.GET()}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getModulesURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async addModule (module) {
    const getAddModuleUrl = `${baseURL}${addModule.GET}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .post(
        getAddModuleUrl,
        {
          id: null,
          name: module.name,
          startDate: module.startDate.toLocaleDateString(),
          endDate: module.endDate.toLocaleDateString(),
          numberOfHours: module.numberOfHours,
          customerId: module.customerId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async editModule (module) {
    const getEditModuleUrl = `${baseURL}${editModule.GET(module.id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .put(
        getEditModuleUrl,
        {
          id: module.id,
          name: module.name,
          startDate: module.startDate.toLocaleDateString(),
          endDate: module.endDate.toLocaleDateString(),
          numberOfHours: module.numberOfHours,
          customerId: module.customerId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getModule (id) {
    const getModuleURL = `${baseURL}${getModule.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getModuleURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async deleteModule (id) {
    const getDeleteModuleUrl = `${baseURL}${deleteModule.GET(id)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .delete(
        getDeleteModuleUrl,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  },

  async getModuleGroups (moduleId) {
    const getModuleGroupsURL = `${baseURL}${getModuleGroups.GET(moduleId)}`
    http.defaults.headers.common.Authorization = `Bearer ${token}`
    return await http
      .get(
        getModuleGroupsURL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${token}`
          }
        }
      )
      .catch((error) => {
        return error
      })
  }
}

export default ModuleService
