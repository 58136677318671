export default Object.freeze({
  getTeachers: {
    GET: () => {
      return 'teachers/teachers'
    }
  },
  addTeacher: {
    GET: 'teachers/teacher'
  },
  editTeacher: {
    GET: (id) => {
      return `teachers/${id}`
    }
  },
  getTeacher: {
    GET: (id) => {
      return `teachers/${id}`
    }
  },
  deleteTeacher: {
    GET: (id) => {
      return `teachers/${id}`
    }
  },
  getTeacherByEmail: {
    GET: (email) => {
      return `teachers/${email}/info`
    }
  }
})
