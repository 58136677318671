export default Object.freeze({
  getStudents: {
    GET: () => {
      return 'students/students'
    }
  },
  addStudent: {
    GET: 'students/student'
  },
  editStudent: {
    GET: (id) => {
      return `students/${id}`
    }
  },
  getStudent: {
    GET: (id) => {
      return `students/${id}`
    }
  },
  deleteStudent: {
    GET: (id) => {
      return `students/${id}`
    }
  },
  getStudentAttendances: {
    GET: (id) => {
      return `students/${id}/attendances`
    }
  },
  getStudentOverview: {
    GET: (id) => {
      return `lessons/${id}/groupattendancesoverview`
    }
  }
})
