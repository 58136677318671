<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObject ? trashObject.name : null "
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <b-table
      v-if="groups.length > 0"
      :checked-rows.sync="checkedRows"
      :checkable="checkable"
      :per-page="perPage"
      :data="groups"
      default-sort="name"
      striped
      hoverable
    >
      <template v-for="column in columns">
        <b-table-column
          :key="column.id"
          v-bind="column"
        >
          <template
            v-if="column.searchable && !column.numeric"
            #searchable="props"
          >
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Cauta..."
              icon="magnify"
              size="is-small"
            />
          </template>
          <template #default="props">
            {{ props.row[column.field] }}
          </template>
        </b-table-column>
      </template>
      <b-table-column
        v-slot="props"
        label="Nivel"
        field="level"
        sortable
      >
        {{ props.row.level }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Manual"
        field="textBook"
        sortable
      >
        {{ props.row.textBook }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Orar"
        field="timetable"
        sortable
      >
        {{ displayLetter(props.row.schedule) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Tip grupa"
        field="type"
        sortable
      >
        {{ props.row.type }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Cost client"
        field="costPerClient"
        sortable
      >
        {{ props.row.costPerClient }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Cost profesor"
        field="costPerTeacher"
        sortable
      >
        {{ props.row.costPerTeacher }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        custom-key="actions"
        cell-class="is-actions-cell"
      >
        <div class="buttons is-right no-wrap">
          <router-link
            :to="{name:'group.edit', params: {id: props.row.id}}"
            class="button is-small is-primary"
          >
            <b-icon
              icon="account-edit"
              size="is-small"
            />
          </router-link>
          <b-button
            native-type="button"
            type="is-danger"
            size="is-small"
            :disabled="props.row.numberOfStudents !== 0"
            @click.prevent="trashModalOpen(props.row)"
          >
            <b-icon
              icon="trash-can"
              size="is-small"
            />
          </b-button>
        </div>
      </b-table-column>
    </b-table>
    <section
      v-if="groups.length ==0"
      slot="empty"
      class="section"
    >
      <div class="content has-text-grey has-text-centered">
        <p>
          <b-icon
            icon="emoticon-sad"
            size="is-large"
          />
        </p>
        <p>Nu exista inregistrari&hellip;</p>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import ModalBox from '@/components/ModalBox.vue'
import * as moment from 'moment/moment'

export default {
  name: 'GroupsTableSample',
  components: { ModalBox },
  props: {
    checkable: Boolean,
    isEmpty: Boolean,
    groups: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { root: { $buefy }, emit }) {
    const perPage = ref(10)

    const checkedRows = ref([])

    const isModalActive = ref(false)

    const trashObject = ref(null)

    const trashModalOpen = obj => {
      trashObject.value = obj
      isModalActive.value = true
    }

    const trashConfirm = () => {
      isModalActive.value = false

      emit('handleDeleteGroup', trashObject.value.id)
    }

    const trashCancel = () => {
      isModalActive.value = false
    }

    return {
      perPage,
      // paginated,
      checkedRows,
      isModalActive,
      trashObject,
      trashModalOpen,
      trashConfirm,
      trashCancel,
      columns: [
        {
          field: 'id',
          label: 'ID',
          numeric: true,
          visible: false
        },
        {
          field: 'name',
          label: 'Nume',
          visible: true,
          sortable: false,
          searchable: true
        },
        {
          field: 'teacherFullName',
          label: 'Profesor',
          visible: true,
          sortable: true,
          searchable: true
        },
        {
          field: 'numberOfStudents',
          label: 'Numar de studenti',
          visible: true,
          sortable: false,
          searchable: false
        }
      ]
    }
  },
  methods: {
    displayLetter (schedule) {
      let displayText = ''
      if (schedule.mondayStart) displayText += `L-${moment(schedule.mondayStart).format('HH:mm')} - ${moment(schedule.mondayEnd).format('HH:mm')},`
      if (schedule.tuesdayStart) displayText += `Ma-${moment(schedule.tuesdayStart).format('HH:mm')} - ${moment(schedule.tuesdayEnd).format('HH:mm')},`
      if (schedule.wednesdayStart) displayText += `Mi-${moment(schedule.wednesdayStart).format('HH:mm')} - ${moment(schedule.wednesdayEnd).format('HH:mm')},`
      if (schedule.thursdayStart) displayText += `J-${moment(schedule.thursdayStart).format('HH:mm')} - ${moment(schedule.thursdayEnd).format('HH:mm')},`
      if (schedule.fridayStart) displayText += `V-${moment(schedule.fridayStart).format('HH:mm')} - ${moment(schedule.fridayEnd).format('HH:mm')},`
      if (schedule.saturdayStart) displayText += `S-${moment(schedule.saturdayStart).format('HH:mm')} - ${moment(schedule.saturdayEnd).format('HH:mm')},`
      if (schedule.sundayStart) displayText += `L-${moment(schedule.sundayStart).format('HH:mm')} - ${moment(schedule.sundayEnd).format('HH:mm')},`
      if (displayText.length > 0) {
        displayText = displayText.slice(0, -1)
      }
      return displayText
    }
  }
}
</script>
