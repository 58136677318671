<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObject ? trashObject.name : null "
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <b-table
      v-if="modules.length > 0"
      :checked-rows.sync="checkedRows"
      :checkable="checkable"
      :per-page="perPage"
      :data="modules"
      default-sort="name"
      striped
      hoverable
    >
      <template v-for="column in columns">
        <b-table-column
          :key="column.id"
          v-bind="column"
        >
          <template
            v-if="column.searchable && !column.numeric"
            #searchable="props"
          >
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Cauta..."
              icon="magnify"
              size="is-small"
            />
          </template>
          <template #default="props">
            {{ props.row[column.field] }}
          </template>
        </b-table-column>
      </template>
      <b-table-column
        v-slot="props"
        label="Data inceput"
        field="startDate"
        sortable
      >
        {{ props.row.startDate | formatDate }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Data final"
        field="endDate"
        sortable
      >
        {{ props.row.endDate | formatDate }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Numar ore"
        field="hours"
        sortable
      >
        {{ props.row.numberOfHours }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        custom-key="actions"
        cell-class="is-actions-cell"
      >
        <div class="buttons is-right no-wrap">
          <router-link
            :to="{name:'module.edit', params: {id: props.row.id}}"
            class="button is-small is-primary"
          >
            <b-icon
              icon="account-edit"
              size="is-small"
            />
          </router-link>
          <b-button
            native-type="button"
            type="is-danger"
            size="is-small"
            :disabled="props.row.numberOfGroups !== 0"
            @click.prevent="trashModalOpen(props.row)"
          >
            <b-icon
              icon="trash-can"
              size="is-small"
            />
          </b-button>
        </div>
      </b-table-column>
    </b-table>
    <section
      v-if="modules.length ==0"
      slot="empty"
      class="section"
    >
      <div class="content has-text-grey has-text-centered">
        <p>
          <b-icon
            icon="emoticon-sad"
            size="is-large"
          />
        </p>
        <p>Nu exista inregistrari&hellip;</p>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import ModalBox from '@/components/ModalBox.vue'

export default {
  name: 'ModulesTableSample',
  components: { ModalBox },
  props: {
    checkable: Boolean,
    isEmpty: Boolean,
    modules: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { root: { $buefy }, emit }) {
    const perPage = ref(10)

    const checkedRows = ref([])

    const isModalActive = ref(false)

    const trashObject = ref(null)

    const trashModalOpen = obj => {
      trashObject.value = obj
      isModalActive.value = true
    }

    const trashConfirm = () => {
      isModalActive.value = false

      emit('handleDeleteModule', trashObject.value.id)
    }

    const trashCancel = () => {
      isModalActive.value = false
    }

    return {
      perPage,
      // paginated,
      checkedRows,
      isModalActive,
      trashObject,
      trashModalOpen,
      trashConfirm,
      trashCancel,
      columns: [
        {
          field: 'id',
          label: 'ID',
          numeric: true,
          visible: false
        },
        {
          field: 'name',
          label: 'Nume',
          visible: true,
          sortable: true,
          searchable: true
        },
        {
          field: 'numberOfGroups',
          label: 'Numar de grupe',
          visible: true,
          sortable: false,
          searchable: false
        }
      ]
    }
  }
}
</script>
