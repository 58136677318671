export default Object.freeze({
  getGroups: {
    GET: () => {
      return 'groups/groups'
    }
  },
  addGroup: {
    GET: 'groups/group'
  },
  editGroup: {
    GET: (id) => {
      return `groups/${id}`
    }
  },
  getGroup: {
    GET: (id) => {
      return `groups/${id}`
    }
  },
  deleteGroup: {
    GET: (id) => {
      return `groups/${id}`
    }
  },
  getGroupStudents: {
    GET: (id) => {
      return `groups/${id}/students`
    }
  },
  getGroupLessons: {
    GET: (id) => {
      return `groups/${id}/lessons`
    }
  },
  getGroupLessonsWithoutAttendance: {
    GET: (id) => {
      return `groups/${id}/withoutattendance`
    }
  },
  getGroupAttendance: {
    GET: (id) => {
      return `groups/${id}/attendance`
    }
  },
  getGroupLevels: {
    GET: () => {
      return 'groups/levels'
    }
  },
  getGroupTypes: {
    GET: () => {
      return 'groups/types'
    }
  },
  getTeacherGroups: {
    GET: (id) => {
      return `groups/teachers/${id}`
    }
  },
  getGroupAttendanceReport: {
    GET: (id) => {
      return `groups/${id}/attendancereport`
    }
  }
})
